import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import { ActionContext } from 'vuex'
import { PaymentMethodMutations } from './payment-method.mutations'
import { PaymentMethodStateInterface } from './payment-method.state'

export enum PaymentMethodActions {
  fetch = 'PAYMENT_METHOD/FETCH'
}

export default {
  [PaymentMethodActions.fetch]: async({ state, commit }: ActionContext<PaymentMethodStateInterface, any>) => {
    await http.get('payment-methods?per_page=999')
      .then((response: IResponse<IModelResponse>) => {
        commit(PaymentMethodMutations.setPaymentMethods, [...state.defaultPaymentMethods, ...response.data.data])
      })
  }
}
