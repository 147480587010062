





































import { Component, Vue } from 'vue-property-decorator';
import __ from '@/helpers/__';

@Component({
  methods: { __ }
})
export default class AuthLayout extends Vue {
  company = ''
  slide = 0
  slides: CarouselItem[] = [
    {
      heading: 'Valdykite sąskaitas',
      text: 'Prisijungę prie Western Investment klientų sistemos Jūs galėsite tvarkyti savo sąskaitas. Jei dar neturite savo prisijungimo paskyros, kviečiame užsiregistruoti - tai užtruks vos kelias minutes.'
    },
    {
      heading: 'Valdykite sąskaitas',
      text: 'Prisijungę prie Western Investment klientų sistemos Jūs galėsite tvarkyti savo sąskaitas. Jei dar neturite savo prisijungimo paskyros, kviečiame užsiregistruoti - tai užtruks vos kelias minutes.'
    },
    {
      heading: 'Valdykite sąskaitas',
      text: 'Prisijungę prie Western Investment klientų sistemos Jūs galėsite tvarkyti savo sąskaitas. Jei dar neturite savo prisijungimo paskyros, kviečiame užsiregistruoti - tai užtruks vos kelias minutes.'
    }
  ]

  mounted():void {
    this.company = process.env.VUE_APP_COMPANY || 'wi'
  }

  get logo(): string {
    if (this.company === 'wedev') {
      return require('@/assets/images/logo-wedev.svg');
    }
    return require('@/assets/images/logo.svg');
  }
}

interface CarouselItem {
  heading: string,
  text: string
}
