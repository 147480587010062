import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import { ActionContext } from 'vuex'
import { VatMutations } from './vat.mutations'
import { VatStateInterface } from './vat.state'

export enum VatActions {
  fetch = 'VAT/FETCH'
}

export default {
  [VatActions.fetch]: async({ commit }: ActionContext<VatStateInterface, any>) => {
    await http.get('vats?per_page=999')
      .then((response: IResponse<IModelResponse>) => {
        commit(VatMutations.setVats, response.data.data)
      })
  }
}
