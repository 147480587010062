





























import { AxiosError } from 'axios'
import _ from 'lodash'
import __ from '@/helpers/__'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  methods: { __ }
})
export default class AlternativeForm extends Vue {
  @Prop({ default: null }) error!: any
  @Prop({ default: false }) hideButtons!: boolean
  formErrors: any = null

  @Watch('error') private onErrorChange(): void {
    if (this.error) this.catchErrors(this.error)
  }

  public catchErrors(error: AxiosError<any>): void {
    if (!error.response) return

    if (error.response.status === 422) {
      error.response.data.errors && this.parseErrors(error.response.data.errors)
    }
  }

  private parseErrors(errors: any): void {
    const formErrors: any = {}

    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key]
      }
    })

    this.setErrors(formErrors)
  }

  setErrors(errors: any): void {
    this.formErrors = errors
  }

  removeError(id: string): void {
    this.formErrors = _.omit(this.errors, id)
  }
}
