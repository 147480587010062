






























import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'

@Component
export default class TextField extends AbstractField {
  @Prop({ default: 'text' }) private readonly type!: string
  passwordToggled: boolean = false

  togglePassword(): void {
    this.passwordToggled = !this.passwordToggled
  }

  get fieldType(): string {
    if (this.type === 'password') return this.passwordToggled ? 'text' : this.type

    return this.type
  }

  @Watch('model') private onModelChange(value: any): void {
    if (this.type === 'number' && value.includes(',')) {
      this.value.replace(/,/g, '.')
    }
  }
}
