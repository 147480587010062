import PaymentMethod from '@/modules/settings/models/payment-method.model'
import { PaymentMethodStateInterface } from './payment-method.state'

export enum PaymentMethodMutations {
  setPaymentMethods = 'PAYMENT_METHODS/SET_PAYMENT_METHODS'
}

export default {
  [PaymentMethodMutations.setPaymentMethods]: (state: PaymentMethodStateInterface, payload: PaymentMethod[]): void => {
    state.paymentMethods = payload
  }
}
