import PaymentMethod from '@/modules/settings/models/payment-method.model';
import { PaymentMethods, PaymentMethodsData } from '@/shared/configs/payment-methods/payment-methods.config';
import moment from 'moment';

export interface PaymentMethodStateInterface {
  paymentMethods: PaymentMethod[],
  defaultPaymentMethods: any
}

export default (): PaymentMethodStateInterface => ({
  paymentMethods: [],
  defaultPaymentMethods: [{
    id: Number.MAX_VALUE,
    title: PaymentMethodsData[PaymentMethods.prepaidInvoice],
    createdAt: moment().format('YYYY-MM-DD')
  }]
})
