






import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
  inject: ['$validator']
})
export default class ErrorMessage extends Vue {
  @Prop({ type: String, required: true }) name!: string

  get error(): any {
    if (this.errors) {
      const error = _.find(_.values(this.errors.items), item => item.field === this.name)
      return error ? error.msg : null
    }

    return null
  }
}
