import unitActions from './unit.actions';
import unitGetters from './unit.getters';
import unitMutations from './unit.mutations';
import unitState from './unit.state';

export default {
  state: unitState,
  getters: unitGetters,
  mutations: unitMutations,
  actions: unitActions
}
