import MiddlewareInterface from '@/router/middleware/middleware.interface';
import { AuthRoutes } from '@/router/routes/auth';
import tokenHelper from '@/helpers/token';

export default function auth({ next, router }: MiddlewareInterface) {
  if (!tokenHelper.isTokenSet()) {
    return router.push({ name: AuthRoutes.login, params: { url: location.pathname + location.search } })
  }
  return next()
}
