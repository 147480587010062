
















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import AuthLayout from '@/views/auth/AuthLayout.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { AuthRoutes } from '@/router/routes/auth'

@Component({
  components: { Form, AuthLayout },
  methods: { __ }
})
export default class SetPasswordForNewUser extends Vue {
  AuthRoutes = AuthRoutes

  token: any = this.$route.query.token
  email: any = this.$route.query.email
  passwordSet: boolean = false

  form: FormBase = new FormBase()
    .setEndpoint('auth/password/set-for-new-user')
    .setNoSubmitButton(true)
    .setOnSuccess(() => {
      this.$router.push({ name: AuthRoutes.passwordChanged, params: { email: this.email } })
    })
    .setFields([
      new Field()
        .setKey('email')
        .setType(FieldTypes.email)
        .setDisabled(true)
        .setValue(this.$route.query.email)
        .setTitle(__('views.auth.email')),
      new Field()
        .setKey('password')
        .setType(FieldTypes.password)
        .setTitle(__('views.auth.password')),
      new Field()
        .setKey('password_confirmation')
        .setType(FieldTypes.password)
        .setTitle(__('views.auth.password-confirm'))
    ])
    .setChangeDataBeforeSubmit((data: any) => ({
      ...data,
      email: this.email,
      token: this.token
    }))
}
