





















import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import __ from '@/helpers/__'

@Component({
  methods: { __ }
})
export default class FileField extends AbstractField {}
