






























import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import MultiSelect from 'vue-multiselect'
import SelectOption from '@/shared/classes/components/form/select-option'
import _ from 'lodash'

@Component({
  components: { MultiSelect }
})
export default class SelectField extends AbstractField {
  @Prop({ default: () => [] }) private readonly options!: SelectOption[]
  @Prop({ default: true }) searchable!: boolean
  @Prop({ default: true }) allowEmpty!: boolean
  @Prop({ default: false }) noLabel!: boolean
  @Prop({ default: false }) small!: boolean
  @Prop({ default: false }) large!: boolean
  @Prop({ default: null }) presetValue!: number | string | null

  mounted():void {
    if (this.presetValue !== null) {
      this.selected = this.options.find(option => option.key === this.presetValue) || null
    }
  }

  selected: SelectOption | null = null

  @Watch('model') private onModelChange(): void {
    const newValue: any = _.find(this.options, (value: SelectOption) => value.key === this.model)
    if (newValue) this.selected = newValue
  }

  setSelected(value: SelectOption): void {
    if (!value) {
      this.emitChange(null)
      return
    }

    this.emitChange(value.key)
  }
}
