import { IFormError, ISubmit, Positions } from '@/shared/interfaces/form/form.interface'
import Field from '@/shared/classes/components/form/field'
import { HttpMethod } from '@/helpers/requests'
import __ from '@/helpers/__'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import FormGroup from '@/shared/classes/components/form/fields/array-field'
import { AxiosError } from 'axios'

export default class FormBase {
  formId: string = Math.random().toString(36).slice(2)
  uuid!: string
  preventUuidSubmit: boolean = false
  endpoint!: string
  groups: FormGroup[] = []
  fields: Field[] = []
  inline: boolean = false
  errors: IFormError = {}
  data: any = {}
  beforeSubmit!: Promise<any>|any
  withoutData: boolean = false
  method: HttpMethod = HttpMethod.POST
  injectValues!: any
  updateInjectValues!: (model: Model<IModelResponse>|any) => object
  model!: any
  entry!: any
  onSetEntry!: (entry: any) => void
  finallyFunc!: (form: any) => void
  autocomplete: string = 'off'
  spacing: boolean = false
  loading: boolean = false
  files: boolean = false
  initialCall: boolean = false
  translatable: boolean = false
  initialValues: any = {}
  noSubmitButton: boolean = false
  changeDataBeforeSubmit!: (data: any, form?: FormBase) => any
  onSuccess!: (data: any, entry?: any) => void
  successAlert!: string
  successTimer: number = 0
  errorToggle: boolean = false
  cancel!: () => void
  submit: ISubmit|boolean = {
    color: 'primary',
    class: '',
    text: __('form.save'),
    position: Positions.right,
    outlined: false,
    depressed: false
  }

  setSuccessTimer(timer: number): this {
    this.successTimer = timer
    return this
  }

  setCancel(cancel: () => void): this {
    this.cancel = cancel
    return this
  }

  setErrorToggle(toggle: boolean): this {
    this.errorToggle = toggle
    return this
  }

  setId(id: string): this {
    this.formId = id
    return this
  }

  setUuid(uuid: string | null): this {
    if (uuid !== null) {
      this.uuid = uuid
    }
    return this
  }

  setPreventUuidSubmit(preventUuidSubmit: boolean | false): this {
    this.preventUuidSubmit = preventUuidSubmit
    return this
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint
    return this
  }

  setInline(inline: boolean): this {
    this.inline = inline
    return this
  }

  setInitialCall(initial: boolean): this {
    this.initialCall = initial
    return this
  }

  setSuccessAlert(successAlert: string): this {
    this.successAlert = successAlert
    return this
  }

  setFields(fields: Field[]): this {
    this.fields = fields
    return this
  }

  addField(field: Field): this {
    this.fields = [...this.fields, field]
    return this
  }

  setInjectValues(injectValues: any): this {
    this.injectValues = injectValues
    return this
  }

  setUpdateInjectValues(updateInjectValues: (model: Model<IModelResponse>|any) => object): this {
    this.updateInjectValues = updateInjectValues
    return this
  }

  setModel(model: any): this {
    this.model = model
    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading
    return this
  }

  setFiles(files: boolean): this {
    this.files = files
    return this
  }

  setSubmit(submit: ISubmit|boolean): this {
    this.submit = submit
    return this
  }

  setOnSuccess(onSuccess: (data: any, entry?: any) => void): this {
    this.onSuccess = onSuccess
    return this
  }

  setData(data: any): this {
    this.data = data
    return this
  }

  setErrors(errors: IFormError): this {
    this.errors = errors
    return this
  }

  changeError(key: string, value: any) {
    this.errors[key] = value
  }

  setMethod(method: HttpMethod): this {
    this.method = method
    return this
  }

  setEntry(entry: any): this {
    this.entry = entry
    this.onSetEntry && this.onSetEntry(entry)
    return this
  }

  setOnSetEntry(onSetEntry: (entry: any) => void): this {
    this.onSetEntry = onSetEntry
    return this
  }

  setOnFinally(finallyFunc: (form: any) => void): this {
    this.finallyFunc = finallyFunc
    return this
  }

  setWithoutData(withoutData: boolean): this {
    this.withoutData = withoutData
    return this
  }

  setSpacing(spacing: boolean): this {
    this.spacing = spacing
    return this
  }

  setAutocompleteEnabled(): this {
    this.autocomplete = ''
    return this
  }

  setBeforeSubmit(beforeSubmit: Promise<any>|any): this {
    this.beforeSubmit = beforeSubmit
    return this
  }

  setNoSubmitButton(noSubmitButton: boolean): this {
    this.noSubmitButton = noSubmitButton
    return this
  }

  setInitialValues(initialValues: any): this {
    this.initialValues = initialValues
    return this
  }

  setChangeDataBeforeSubmit(changeDataBeforeSubmit: (data: any, form?: FormBase) => any): this {
    this.changeDataBeforeSubmit = changeDataBeforeSubmit
    return this
  }

  setGroups(groups: FormGroup[]): this {
    this.groups = groups
    return this
  }

  public catchErrors(error: AxiosError<any>) {
    if (!error.response) return

    if (error.response.status === 422) {
      error.response.data.errors && this.parseErrors(error.response.data.errors)
    }
  }

  private parseErrors(errors: any) {
    const formErrors: IFormError = {}

    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key]
      }
    })

    this.setErrors(formErrors)
  }
}
