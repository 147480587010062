import Field from '@/shared/classes/components/form/field'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'

export interface IFormProps {
  endpoint?: string;
  fields: Field[];
  injectValues?: any;
  model?: any;
}

export interface IFormError {
  [x: string]: IFieldError | boolean
}

export interface IFieldError {
  has: boolean;
  count: number;
  messages: string[];
}

export interface IFieldIcons {
  append?: IFieldIcon;
  prepend?: IFieldIcon;
}

export interface IFieldIcon {
  name: string;
  callback?: () => void;
}

export enum Positions {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface ISubmit {
  color?: string
  class?: string
  text?: string
  position?: Positions
  outlined?: boolean
  depressed?: boolean
}

export interface IFilter {
  type: FilterOperators
  name: string
  value: any
}

export interface ILoadItemsProps {
  endpoint: string
  value: string
  title: string
  params?: any
  filters?: IFilter[]
  perPage?: number
}

export interface ISearchUpdateData {
  key: string
  label: string
}
