import IModelResponse from '@/shared/interfaces/model-response.interface'

export default class Model<T> {
  id!: number
  createdAt!: string
  deletedAt!: string
  name!: string

  constructor(data: T) {
    this.transform(data)
  }

  transform(data: T): void {
    // @ts-ignore
    this.id = data.id
    // @ts-ignore
    this.createdAt = data.created_at
    // @ts-ignore
    this.deletedAt = data.deleted_at
  }

  setRelation(
    Model: Model<IModelResponse> | any,
    data: IModelResponse | IModelResponse[]
  ): Model<IModelResponse> | Array<Model<IModelResponse>> | undefined | any {
    if (Array.isArray(data)) {
      return data ? data.map(item => new Model(item)) : undefined
    }
    return data ? new Model(data) : undefined
  }

  round(number: number, decimal: number): number {
    return Number(number.toFixed(decimal))
  }
}
