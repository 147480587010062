import { UserStateInterface } from './user.state';

export enum UserGetters {
  getUser = 'USER/GET_USER',
  getToken = 'USER/GET_TOKEN',
  getRefreshing = 'USER/GET_REFRESHING'
}

export default {
  [UserGetters.getUser]: (state: UserStateInterface): any => state.user,
  [UserGetters.getToken]: (state: UserStateInterface): any => state.token,
  [UserGetters.getRefreshing]: (state: UserStateInterface): any => state.refreshing
}
