















import { Component, Prop, Vue } from 'vue-property-decorator'
import { FieldTypes, fieldTypesMap } from '@/shared/components/form/field-types'
import Field from '@/shared/classes/components/form/field'
import FormBase from '@/shared/classes/components/form/form-base'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter';

@Component
export default class FormField extends Vue {
    @Prop() field!: Field
    @Prop() fullKey!: string
    @Prop() form!: FormBase
    @Prop() group!: any
    @Prop() rowIndex!: number
    @Prop() filter!: DataTableFilter

    getComponent(type: FieldTypes) {
      if (Object.prototype.hasOwnProperty.call(fieldTypesMap, type)) {
        return fieldTypesMap[type]
      }
    }

    get visibility(): boolean {
      if (!this.form) return true

      return this.field.visibleIf(this.form.data, this.field.index, this.group)
    }
}
