import logoActions from './logo.actions'
import logoGetters from './logo.getters'
import logoMutations from './logo.mutations'
import logoState from './logo.state'

export default {
  state: logoState,
  getters: logoGetters,
  mutations: logoMutations,
  actions: logoActions
}
