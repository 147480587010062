
import { FieldSizes } from '@/shared/classes/components/form/field'
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AbstractField extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly title!: string
  @Prop() readonly placeholder!: string
  @Prop({ default: false }) readonly readonly!: boolean
  @Prop({ default: false }) readonly required!: boolean
  @Prop({ default: null }) value!: any
  @Prop({ default: null }) readonly additionalClass!: string
  @Prop({ default: null }) readonly additionalInputClass!: string
  @Prop({ default: FieldSizes.full }) readonly size!: FieldSizes
  @Prop({ default: false }) readonly requiredDecoration!: boolean
  @Prop() private readonly error!: any
  @Prop() private readonly props!: any

  model: any = this.value

  @Watch('value') private onValueChange(newValue: any): void {
    this.model = newValue
    this.removeError()
  }

  emitChange(event: any): void {
    this.$emit('input', event)
    this.$emit('onChange', event)
    this.$emit('clearError', this.id)
  }

  get getError(): any | boolean | null {
    if (!this.error && (!this.props || !this.props.error)) return null

    if ('error' in this.props) return _.get(this.props.error, this.id)

    return _.get(this.error, this.id)
  }

  removeError() {
    if (!this.getError) return

    if (this.props.events && 'removeError' in this.props.events) {
      this.props.events.removeError(this.id)
      return
    }

    this.$emit('deleteError', this.id)
  }

  get getErrorState(): boolean | null {
    return this.getError ? false : null
  }
}
