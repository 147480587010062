import http from '@/shared/helpers/http'
import { AxiosResponse } from 'axios'

export default class AuthService {
  static async login(data: any): Promise<AxiosResponse> {
    return http.post('auth/login', data).then((response: any) => response.data)
  }

  static async resetPassword(data: any): Promise<AxiosResponse> {
    return http.post('auth/password/reset', data)
  }
}
