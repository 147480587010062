import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IUserResponse from '@/modules/user/interfaces/user-response.interface'

export default class User extends Model<IModelResponse> {
  static ALIAS: string = 'user'

  type!: string
  email!: string
  firstName!: string
  lastName!: string
  fullName!: string
  phone!: string
  position!: string
  disabled!: boolean
  personalCode!: string
  address!: string

  transform(data: IUserResponse): void {
    super.transform(data)

    this.type = data.type
    this.email = data.email
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.fullName = data.full_name
    this.phone = data.phone
    this.position = data.position
    this.disabled = !!data.disabled
    this.personalCode = data.personal_code
    this.address = data.address
  }
}
