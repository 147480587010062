




























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import User from '@/modules/user/models/user.model'
import { UserGetters } from '@/store/modules/user/user.getters'
import UserDataModal from '@/shared/components/UserDataModal.vue'
import PageHeader from '@/components/layout/PageHeader.vue';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import Sidebar from '@/components/layout/Sidebar.vue';

@Component({
  components: {
    Sidebar,
    Breadcrumbs,
    PageHeader,
    UserDataModal
  },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  },
  methods: {
    __
  }
})
export default class Topnav extends Vue {
  @Prop({ default: null }) sidebarStatus?: boolean | null

  selectedParentMenu: string = ''
  isMenuOver: boolean = false
  GeneralRoutes = GeneralRoutes
  user!: User
  heading: string | null = null
  subheading: string | null = null
  subheadingNote: string | null = null
  link: string | null = null
  linkTitle: string | null = null
  linkEmit: string | null = null
  badge: string | null = null

  mounted(): void {
    this.toggleSelectedParentMenu()

    document.addEventListener('click', this.returnSelectedParentMenu)
  }

  created(): void {
    if (typeof this.$route.meta?.heading !== 'undefined') {
      this.heading = this.$route.meta.heading
    }

    if (typeof this.$route.meta?.subheading !== 'undefined') {
      this.subheading = this.$route.meta.subheading
    }

    if (typeof this.$route.meta?.subheading !== 'undefined') {
      this.subheadingNote = this.$route.meta.subheadingNote
    }

    if (typeof this.$route.meta?.link !== 'undefined') {
      this.link = this.$route.meta.link
    }

    if (typeof this.$route.meta?.linkTitle !== 'undefined') {
      this.linkTitle = this.$route.meta.linkTitle
    }

    if (typeof this.$route.meta?.linkEmit !== 'undefined') {
      this.linkEmit = this.$route.meta.linkEmit
    }

    if (typeof this.$route.meta?.badge !== 'undefined') {
      this.badge = this.$route.meta.badge
    }

    this.$root.$on('headingChange', (data: HeadingPayload) => {
      if (typeof data.heading !== 'undefined') {
        this.heading = data.heading
      }
      if (typeof data.subheading !== 'undefined') {
        this.subheading = data.subheading
      }
      if (typeof data.subheadingNote !== 'undefined') {
        this.subheadingNote = data.subheadingNote
      }
      if (typeof data.badge !== 'undefined') {
        this.badge = data.badge
      }
    })
  }

  @Watch('$route', { deep: true }) onRouteChange(newRoute: any, oldRoute: any): void {
    if (newRoute.name === oldRoute.name) {
      return
    }
    this.heading = newRoute.meta?.heading || null
    this.subheading = newRoute.meta?.subheading || null
    this.subheadingNote = newRoute.meta?.subheadingNote || null
    this.link = newRoute.meta?.link || null
    this.linkTitle = newRoute.meta?.linkTitle || null
    this.linkEmit = newRoute.meta?.linkEmit || null
    this.badge = newRoute.meta?.badge || null
  }

  returnSelectedParentMenu(): void {
    if (!this.isMenuOver) {
      this.toggleSelectedParentMenu()
    }
  }

  toggleSelectedParentMenu(): void {
    const currentParentUrl = this.$route.path.split('/').filter(x => x !== '')[1]

    if (typeof currentParentUrl !== 'undefined') {
      this.selectedParentMenu = currentParentUrl.toLowerCase()
    } else {
      this.selectedParentMenu = 'dashboards'
    }
  }

  toggleSidebar() {
    this.$root.$emit('sidebarStatus', !this.sidebarStatus)
  }
}

interface HeadingPayload {
  heading?: string
  subheading?: string
  subheadingNote?: string
  badge?: string
}
