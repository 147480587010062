import signatureActions from './signature.actions'
import signatureGetters from './signature.getters'
import signatureMutations from './signature.mutations'
import signatureState from './signature.state'

export default {
  state: signatureState,
  getters: signatureGetters,
  mutations: signatureMutations,
  actions: signatureActions
}
