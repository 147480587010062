import Vat from '@/modules/settings/models/vat.model'
import { VatStateInterface } from './vat.state'

export enum VatMutations {
  setVats = 'VAT/SET_VATS'
}

export default {
  [VatMutations.setVats]: (state: VatStateInterface, payload: Vat[]): void => {
    state.vats = payload
  }
}
