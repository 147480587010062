










































import { Component, Vue } from 'vue-property-decorator';
import AuthLayout from '@/views/auth/AuthLayout.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { AuthRoutes } from '@/router/routes/auth';
import AuthService from '@/services/AuthService';

@Component({
  components: { Form, AuthLayout },
  methods: { __ }
})
export default class LoginUser extends Vue {
  AuthRoutes = AuthRoutes
  error: any = {}
  data: any = {
    email: this.$route.params.email || ''
  }

  submit(): void {
    AuthService.resetPassword(this.data)
      .then(() => {
        this.$router.push({ name: AuthRoutes.login });
      })
      .catch((error: any) => (this.error = error))
  }
}
