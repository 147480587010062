

























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import User from '@/modules/user/models/user.model'
import { UserActions } from '@/store/modules/user/user.actions'
import Form from '@/shared/components/form/Form.vue'
import { UserGetters } from '@/store/modules/user/user.getters'
import FormBase from '@/shared/classes/components/form/form-base'
import { HttpMethod } from '@/helpers/requests'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import http from '@/shared/helpers/http';
import { SignatureActions } from '@/store/modules/signature/signature.actions';
import { SignatureGetters } from '@/store/modules/signature/signature.getters';

@Component({
  components: {
    Form
  },
  computed: {
    ...mapGetters({
      token: UserGetters.getToken,
      user: UserGetters.getUser,
      signature: SignatureGetters.getSignature
    })
  },
  methods: {
    __
  }
})
export default class Topnav extends Vue {
  GeneralRoutes = GeneralRoutes
  user!: User
  modalShown: boolean = false
  form: FormBase | null = null
  passwordForm: FormBase | null = null
  token!: string
  passwordChanged: boolean = false
  dataChanged: boolean = false
  signature!: any
  newSignature: any = null
  error: any = null

  editProfile(): void {
    this.modalShown = !this.modalShown
  }

  mounted(): void {
    this.$store.dispatch(SignatureActions.fetchUser)
    this.passwordForm = new FormBase()
      .setEndpoint(`users/${this.user.id}/password`)
      .setNoSubmitButton(true)
      .setOnSuccess(() => (this.passwordChanged = true))
      .setFields([
        new Field()
          .setKey('password')
          .setType(FieldTypes.password)
          .setTitle(__('views.auth.password')),
        new Field()
          .setKey('password_confirmation')
          .setType(FieldTypes.password)
          .setTitle(__('views.auth.password-confirm'))
      ])
      .setChangeDataBeforeSubmit((data: any) => ({
        ...data,
        email: this.user.email,
        token: this.token
      }))
  }

  private onModalShow(): void {
    this.passwordChanged = false
    this.form = new FormBase()
      .setUuid(this.user.id.toString())
      .setId(this.user.id.toString())
      .setMethod(HttpMethod.PUT)
      .setEndpoint('/users')
      .setModel(User)
      .setOnSuccess(() => {
        this.dataChanged = true
        this.$store.dispatch(UserActions.refreshUser)

        setTimeout(() => {
          this.dataChanged = false
        }, 5000)
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('first_name')
          .setEntryKey('firstName')
          .setRequiredDecoration(true)
          .setTitle(__('views.users.form.first_name'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('last_name')
          .setEntryKey('lastName')
          .setTitle(__('views.users.form.last_name'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('email')
          .setTitle(__('views.users.form.email'))
          .setRequiredDecoration(true)
          .setType(FieldTypes.email)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('phone')
          .setTitle(__('views.users.form.phone'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('personal_code')
          .setTitle(__('views.users.form.personal_code'))
          .setEntryKey('personalCode')
          .setSize(FieldSizes.half),
        new Field()
          .setKey('position')
          .setTitle(__('views.users.form.position'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true),
        new Field().setKey('address').setTitle(__('views.users.form.address'))
      ])
      .setData(this.user)
      .setInjectValues({
        type: this.user.type
      })
  }

  private submitForm(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }

  private submitPasswordForm(): void {
    const ref: any = this.$refs.passwordForm
    ref.submit()
  }

  async uploadSignature(event: File): Promise<void> {
    const formData = new FormData()
    formData.append('type', 'users')
    formData.append('file', event)
    await http
      .post('signs', formData)
      .finally(() => {
        this.$store.dispatch(SignatureActions.fetchUser).then(() => {
          if (this.form) {
            this.form.setLoading(false)
          }
        })
      })
      .catch((error: any) => {
        if (this.form) {
          this.form.catchErrors(error)
          this.form.data.image = null
        }
      })
  }
}
