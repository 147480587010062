import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import { ActionContext } from 'vuex'
import { UnitMutations } from './unit.mutations'
import { UnitStateInterface } from './unit.state'

export enum UnitActions {
  fetch = 'UNIT/FETCH'
}

export default {
  [UnitActions.fetch]: async({ commit }: ActionContext<UnitStateInterface, any>) => {
    await http.get('units?per_page=999')
      .then((response: IResponse<IModelResponse>) => {
        commit(UnitMutations.setUnits, response.data.data)
      })
  }
}
