import { AxiosError } from 'axios';
import _ from 'lodash'
import ErrorMessage from '@/plugins/ErrorMessage.vue'

export default {
  install(Vue: any) {
    Vue.component('ErrorMessage', ErrorMessage)
    Vue.component('vue-perfect-scrollbar', () => import('vue-perfect-scrollbar'));

    Vue.prototype.setErrorsFromResponse = function(error: AxiosError) {
      this.errors.clear()

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors
        _.each(errors, (error: Array<string>, field: string) => {
          this.errors.add({ field, msg: error[0] })
        })
      }
    }
  }
}
