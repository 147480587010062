
















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import AuthLayout from '@/views/auth/AuthLayout.vue'
import { AuthRoutes } from '@/router/routes/auth'

@Component({
  components: { Form, AuthLayout },
  methods: { __ }
})
export default class PasswordChanged extends Vue {
  AuthRoutes = AuthRoutes
}
