import { ActionContext } from 'vuex'

interface IStateSidebarCompact {
  compactSidebarToggleProperties: {
    isSideNavOpen: boolean,

    isActiveSecondarySideNav: boolean
  },
  compactLeftSideBarBgColor: string
}

const state: IStateSidebarCompact = {
  compactSidebarToggleProperties: {
    isSideNavOpen: true,

    isActiveSecondarySideNav: false
  },
  compactLeftSideBarBgColor: 'sidebar-dark-purple'
};

const getters = {
  getCompactSideBarToggleProperties: (state: IStateSidebarCompact) =>
    state.compactSidebarToggleProperties,
  getcompactLeftSideBarBgColor: (state: IStateSidebarCompact) => state.compactLeftSideBarBgColor
};

const actions = {
  changeCompactSidebarProperties({ commit }: ActionContext<IStateSidebarCompact, any>) {
    commit('toggleCompactSidebarProperties');
  },

  changecompactLeftSideBarBgColor({ commit }: ActionContext<IStateSidebarCompact, any>, data: any) {
    commit('togglecompactLeftSideBarBgColor', data);
  }
};

const mutations = {
  toggleCompactSidebarProperties: (state: IStateSidebarCompact) =>
    (state.compactSidebarToggleProperties.isSideNavOpen = !state
      .compactSidebarToggleProperties.isSideNavOpen),
  togglecompactLeftSideBarBgColor(state: IStateSidebarCompact, data: any) {
    state.compactLeftSideBarBgColor = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
