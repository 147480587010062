const authKey = 'auth_token'

const getToken = (): null | string => localStorage.getItem(authKey)
const setToken = (token: string) => localStorage.setItem(authKey, token)
const clearToken = (): void => localStorage.removeItem(authKey)
const isTokenSet = (): boolean => localStorage.getItem(authKey) !== null

const tokenHelper = {
  getToken,
  setToken,
  clearToken,
  isTokenSet
}

export default tokenHelper
