import { ActionContext } from 'vuex'

interface IStateLargeSidebar {
  sidebarToggleProperties: {
    isSideNavOpen: boolean,
    isSecondarySideNavOpen: boolean,
    isActiveSecondarySideNav: boolean
  }
}

const state: IStateLargeSidebar = {
  sidebarToggleProperties: {
    isSideNavOpen: true,
    isSecondarySideNavOpen: false,
    isActiveSecondarySideNav: false
  }
};

const getters = {
  getSideBarToggleProperties: (state: IStateLargeSidebar) => state.sidebarToggleProperties
};

const actions = {
  changeSidebarProperties({ commit }: ActionContext<IStateLargeSidebar, any>) {
    commit('toggleSidebarProperties');
  },
  changeSecondarySidebarProperties({ commit }: ActionContext<IStateLargeSidebar, any>) {
    commit('toggleSecondarySidebarProperties');
  },
  changeSecondarySidebarPropertiesViaMenuItem({ commit }: ActionContext<IStateLargeSidebar, any>, data: any) {
    commit('toggleSecondarySidebarPropertiesViaMenuItem', data);
  },
  changeSecondarySidebarPropertiesViaOverlay({ commit }: ActionContext<IStateLargeSidebar, any>) {
    commit('toggleSecondarySidebarPropertiesViaOverlay');
  }
};

const mutations = {
  toggleSidebarProperties: (state: IStateLargeSidebar) =>
    (state.sidebarToggleProperties.isSideNavOpen = !state
      .sidebarToggleProperties.isSideNavOpen),

  toggleSecondarySidebarProperties: (state: IStateLargeSidebar) =>
    (state.sidebarToggleProperties.isSecondarySideNavOpen = !state
      .sidebarToggleProperties.isSecondarySideNavOpen),
  toggleSecondarySidebarPropertiesViaMenuItem(state: IStateLargeSidebar, data: any) {
    state.sidebarToggleProperties.isSecondarySideNavOpen = data;
    state.sidebarToggleProperties.isActiveSecondarySideNav = data;
  },
  toggleSecondarySidebarPropertiesViaOverlay(state: IStateLargeSidebar) {
    state.sidebarToggleProperties.isSecondarySideNavOpen = !state
      .sidebarToggleProperties.isSecondarySideNavOpen;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
