import VeeValidate from 'vee-validate'
import SetupPlugins from '@/plugins/setup-plugins'
import VueApexCharts from 'vue-apexcharts'
import TextField from '@/shared/components/form/fields/TextField.vue'
import SearchableField from '@/shared/components/form/fields/SearchableField.vue'
import CheckboxField from '@/shared/components/form/fields/CheckboxField.vue'
import RichEditorField from '@/shared/components/form/fields/RichEditorField.vue'
import DateField from '@/shared/components/form/fields/DateField.vue'
import TimeField from '@/shared/components/form/fields/TimeField.vue'
import SelectField from '@/shared/components/form/fields/SelectField.vue'
import FileField from '@/shared/components/form/fields/FileField.vue'
import FormTemplate from '@/shared/components/form/FormTemplate.vue'
import Vue from 'vue'

export default {
  install(): void {
    Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
    Vue.use(SetupPlugins)
    Vue.use(VueApexCharts)
    Vue.component('apexchart', VueApexCharts)
    Vue.config.productionTip = false

    // Components

    Vue.component('TextField', TextField)
    Vue.component('SearchableField', SearchableField)
    Vue.component('CheckboxField', CheckboxField)
    Vue.component('RichEditorField', RichEditorField)
    Vue.component('DateField', DateField)
    Vue.component('TimeField', TimeField)
    Vue.component('SelectField', SelectField)
    Vue.component('FormTemplate', FormTemplate)
    Vue.component('FileField', FileField)
  }
}
