import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import { ActionContext } from 'vuex'
import { LogoMutations } from './logo.mutations'
import { LogoStateInterface } from './logo.state'

export enum LogoActions {
  fetch = 'LOGO/FETCH'
}

export default {
  // eslint-disable-next-line space-before-function-paren
  [LogoActions.fetch]: async ({ commit }: ActionContext<LogoStateInterface, any>) => {
    await http.get('logos').then((response: IResponse<IModelResponse>) => {
      const logo = response.data.data[0]
      if (logo && logo.file_type === 'svg') logo.file_type = 'svg+xml'
      commit(LogoMutations.setLogo, logo || null)
    })
  }
}
