import { FieldTypes } from '@/shared/components/form/field-types'
import FormBase from './form-base'
import SelectOption from '@/shared/classes/components/form/select-option'

export enum FieldSizes {
  full = 'col-12',
  half = 'col-12 col-lg-6',
  quarter = 'col-6 col-lg-3',
  threeQuarters = 'col-9',
  oneTwelfth = 'col-12 col-sm-6 col-lg-1',
  oneTwelfthBig = 'col-10 col-sm-6 col-lg-1',
  oneTwelfthSmall = 'col-2 col-lg-1',
  twoTwelfth = 'col-6 col-lg-2',
  twoTwelfthBig = 'col-12 col-sm-6 col-lg-2',
  threeTwelfth = 'col-12 col-sm-6 col-lg-3',
  fourTwelfth = 'col-6 col-lg-4',
  fiveTwelfth = 'col-6 col-lg-5',
  sevenTwelfth = 'col-7',
  eightTwelfth = 'col-8',
  tenTwelfth = 'col-10',
  elevenTwelfth = 'col-10 col-lg-11',
  auto = 'col',
  null = ''
}

export default class Field {
  type: FieldTypes = FieldTypes.text
  size: FieldSizes | string = FieldSizes.full
  key: string = ''
  entryKey: string | null = null
  arrayKey: string | null = null
  title!: string | null
  hint: string | null = null
  classes: string = ''
  meta: any = {}
  value: any = ''
  placeholder!: string
  group!: string
  fieldGroup!: string
  dense: boolean = false
  disabled: (() => boolean) | boolean = false
  hideDetails: boolean = false
  translatable: boolean = false
  visibleIf: (values: any, index?: number, group?: any) => boolean = () => true
  onChange!: (form: FormBase, index?: number, group?: any, rowIndex?: number) => void
  onCreated!: (form: FormBase, index?: number, group?: any, rowIndex?: number) => void
  onEnter: () => void = () => {}
  append!: any
  appendOuter!: any
  tooltip!: string
  beforeChange!: (value: any) => any
  rows: number | null = null
  component!: any
  hidden: boolean = false
  required: boolean = false
  switchBox: boolean = false
  index!: number
  maxValue!: number
  minValue!: number
  selectOptions!: SelectOption[]
  keys: string[] = []
  rowIndex!: number
  checkedValue: any = '1'
  uncheckedValue: any = '0'
  requiredDecoration = false
  notFoundComponent!: object
  arrayValue: (data: any) => number = () => 0

  setType(type: FieldTypes): this {
    this.type = type
    return this
  }

  setRequiredDecoration(value: boolean): this {
    this.requiredDecoration = value
    return this
  }

  setKey(key: string): this {
    this.key = key
    return this
  }

  setKeys(keys: string[]): this {
    this.keys = keys
    return this
  }

  setEntryKey(entryKey: string): this {
    this.entryKey = entryKey
    return this
  }

  setArrayKey(arrayKey: string): this {
    this.arrayKey = arrayKey
    return this
  }

  setArrayValue(arrayValue: (data: any) => number): this {
    this.arrayValue = arrayValue
    return this
  }

  setTitle(title: string): this {
    this.title = title
    return this
  }

  setValue(value: any): this {
    this.value = value
    return this
  }

  setCheckedValue(value: any): this {
    this.checkedValue = value
    return this
  }

  setUncheckedValue(value: any): this {
    this.uncheckedValue = value
    return this
  }

  setSelectOptions(selectOptions: SelectOption[]): this {
    this.selectOptions = selectOptions
    return this
  }

  setDense(dense: boolean): this {
    this.dense = dense
    return this
  }

  setHideDetails(hideDetails: boolean): this {
    this.hideDetails = hideDetails
    return this
  }

  setOnEnter(onEnter: () => void): this {
    this.onEnter = onEnter
    return this
  }

  setSize(size: FieldSizes | string): this {
    this.size = size
    return this
  }

  setDisabled(disabled: boolean | (() => boolean)): this {
    this.disabled = disabled
    return this
  }

  setOnCreated(onCreated: (form: FormBase, index?: number, group?: any, rowIndex?: number) => void): this {
    this.onCreated = onCreated
    return this
  }

  setMeta(meta: any): this {
    this.meta = meta
    return this
  }

  setVisibleIf(visibleIf: (values: any, index?: number, group?: any) => boolean): this {
    this.visibleIf = visibleIf
    return this
  }

  setAppend(append: any): this {
    this.append = append
    return this
  }

  setAppendOuter(appendOuter: any): this {
    this.appendOuter = appendOuter
    return this
  }

  setOnChange(onChange: (form: FormBase, index?: number, group?: any, rowIndex?: number) => void): this {
    this.onChange = onChange
    return this
  }

  setTranslatable(translatable: boolean): this {
    this.translatable = translatable
    return this
  }

  setHint(hint: string): this {
    this.hint = hint
    return this
  }

  setBeforeChange(beforeChange: (value: any) => any): this {
    this.beforeChange = beforeChange
    return this
  }

  setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder
    return this
  }

  setGroup(group: string): this {
    this.group = group
    return this
  }

  setFieldGroup(fieldGroup: string): this {
    this.fieldGroup = fieldGroup
    return this
  }

  setTooltip(tooltip: string): this {
    this.tooltip = tooltip
    return this
  }

  setClasses(classes: string): this {
    this.classes = classes
    return this
  }

  setRows(rows: number): this {
    this.rows = rows
    return this
  }

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setHidden(hidden: boolean): this {
    this.hidden = hidden
    return this
  }

  isRequired(): this {
    this.required = true
    return this
  }

  setIndex(index: number): this {
    this.index = index
    return this
  }

  setMaxValue(maxValue: number): this {
    this.maxValue = maxValue
    return this
  }

  setMinValue(minValue: number): this {
    this.minValue = minValue
    return this
  }

  setRowIndex(rowIndex: number): this {
    this.rowIndex = rowIndex
    return this
  }

  setNotFoundComponent(notFoundComponent: object): this {
    this.notFoundComponent = notFoundComponent
    return this
  }
}
