





















import { Component, Prop, Vue } from 'vue-property-decorator'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import { GeneralRoutes } from '@/router/routes/general';
import { InvoiceStatusKeysData } from '@/shared/configs/invoice/invoice.config';

@Component({
  components: { Breadcrumbs }
})
export default class PageHeader extends Vue {
  @Prop() title!: string
  @Prop() subTitle!: string
  @Prop() subTitleNote!: string
  @Prop() link!: string
  @Prop() linkTitle!: string
  @Prop() linkEmit!: string
  @Prop() badge!: string
  @Prop({ default: '' }) classes!: string
  @Prop({ default: false }) loading!: boolean
  @Prop() goBackAction!: () => void

  GeneralRoutes = GeneralRoutes

  onCLick() {
    if (!this.linkEmit) return
    this.$root.$emit(this.linkEmit)
  }

  invoiceBadge(statusKey: string): string {
    return InvoiceStatusKeysData[statusKey]
  }
}
