export enum FieldTypes {
  text = 'text',
  email = 'email',
  checkbox = 'checkbox',
  button = 'button',
  custom = 'custom',
  array = 'array',
  searchable = 'searchable',
  date = 'date',
  time = 'time',
  textarea = 'textarea',
  richEditor = 'rich-editor',
  deleteAction = 'delete-action',
  number = 'number',
  password = 'password',
  select = 'select',
  arrayDrag = 'array-dragable',
  dateFilter = 'date-filter',
  logo = 'logo',
  customDropdown = 'custom-dropdown',
  checkboxBlock = 'checkbox-block'
}

export const fieldTypesMap: any = {
  [FieldTypes.text]: () => import('@/shared/components/form/field-types/TextField.vue'),
  [FieldTypes.email]: () => import('@/shared/components/form/field-types/EmailField.vue'),
  [FieldTypes.checkbox]: () => import('@/shared/components/form/field-types/CheckboxField.vue'),
  [FieldTypes.button]: () => import('@/shared/components/form/field-types/ButtonField.vue'),
  [FieldTypes.custom]: () => import('@/shared/components/form/field-types/CustomField.vue'),
  [FieldTypes.array]: () => import('@/shared/components/form/field-types/ArrayField.vue'),
  [FieldTypes.searchable]: () => import('@/shared/components/form/field-types/SearchableField.vue'),
  [FieldTypes.date]: () => import('@/shared/components/form/field-types/DateField.vue'),
  [FieldTypes.time]: () => import('@/shared/components/form/field-types/TimeField.vue'),
  [FieldTypes.textarea]: () => import('@/shared/components/form/field-types/TextareaField.vue'),
  [FieldTypes.richEditor]: () => import('@/shared/components/form/field-types/RichEditorField.vue'),
  [FieldTypes.deleteAction]: () => import('@/shared/components/form/field-types/actions/DeleteActionField.vue'),
  [FieldTypes.number]: () => import('@/shared/components/form/field-types/NumberField.vue'),
  [FieldTypes.password]: () => import('@/shared/components/form/field-types/PasswordField.vue'),
  [FieldTypes.select]: () => import('@/shared/components/form/field-types/SelectField.vue'),
  [FieldTypes.arrayDrag]: () => import('@/shared/components/form/field-types/ArrayDragDropField.vue'),
  [FieldTypes.dateFilter]: () => import('@/shared/components/form/field-types/filter/DateFilter.vue'),
  [FieldTypes.logo]: () => import('@/shared/components/form/field-types/LogoField.vue'),
  [FieldTypes.customDropdown]: () => import('@/shared/components/form/field-types/CustomDropdown.vue'),
  [FieldTypes.checkboxBlock]: () => import('@/shared/components/form/field-types/CheckboxBlockField.vue')
}
