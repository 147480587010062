import { LogoStateInterface } from './logo.state'

export enum LogoMutations {
  setLogo = 'LOGO/SET_LOGO'
}

export default {
  [LogoMutations.setLogo]: (state: LogoStateInterface, payload: any): void => {
    state.logo = payload
  }
}
