import vatActions from './vat.actions';
import vatGetters from './vat.getters';
import vatMutations from './vat.mutations';
import vatState from './vat.state';

export default {
  state: vatState,
  getters: vatGetters,
  mutations: vatMutations,
  actions: vatActions
}
