import paymentMethodActions from './payment-method.actions';
import paymentMethodGetters from './payment-method.getters';
import paymentMethodMutations from './payment-method.mutations';
import paymentMethodState from './payment-method.state';

export default {
  state: paymentMethodState,
  getters: paymentMethodGetters,
  mutations: paymentMethodMutations,
  actions: paymentMethodActions
}
