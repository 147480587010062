import tokenHelper from '@/helpers/token'
import IUserResponse from '@/modules/user/interfaces/user-response.interface'
import User from '@/modules/user/models/user.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import { UserStateInterface } from '@/store/modules/user/user.state'
import { ActionContext } from 'vuex'
import { UserMutations } from './user.mutations'

export enum UserActions {
  logout = 'USER/LOGOUT',
  refreshToken = 'USER/REFRESH_TOKEN',
  refreshUser = 'USER/REFRESH_USER'
}

export default {
  [UserActions.logout]: ({ commit }: ActionContext<UserStateInterface, any>): void => {
    commit(UserMutations.setUser, null)
    commit(UserMutations.setToken, null)
    tokenHelper.clearToken()
  },
  [UserActions.refreshToken]: async({ commit }: ActionContext<UserStateInterface, any>): Promise<void> => {
    http
      .post('/auth/refresh')
      .then((response: any) => {
        tokenHelper.setToken(response.data.access_token)
        commit(UserMutations.setToken, response.data.access_token)
        commit(UserMutations.setRefreshing, false)
      })
  },
  [UserActions.refreshUser]: async({ commit }: ActionContext<UserStateInterface, any>): Promise<void> => {
    http
      .get('/auth/me')
      .then((response: IResponse<IUserResponse>) => {
        commit(UserMutations.setUser, new User(response.data.data))
        commit(UserMutations.setToken, tokenHelper.getToken())
        commit(UserMutations.setRefreshing, false)
      })
  }
}
