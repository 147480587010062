import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import { ActionContext } from 'vuex'
import { SignatureMutations } from './signature.mutations'
import { SignatureStateInterface } from './signature.state'

export enum SignatureActions {
  fetchMain = 'SIGNATURE/FETCH_MAIN',
  fetchUser = 'SIGNATURE/FETCH_USER'
}

export default {
  // eslint-disable-next-line space-before-function-paren
  [SignatureActions.fetchMain]: async({ commit }: ActionContext<SignatureStateInterface, any>) => {
    await http.get('signs').then((response: IResponse<IModelResponse>) => {
      const logo = response.data.data.find((image:any) => {
        return image.type === 'settings'
      })
      if (logo && logo.file_type === 'svg') logo.file_type = 'svg+xml'
      commit(SignatureMutations.setSignature, logo || null)
    })
  },

  [SignatureActions.fetchUser]: async({ commit }: ActionContext<SignatureStateInterface, any>) => {
    await http.get('signs').then((response: IResponse<IModelResponse>) => {
      const logo = response.data.data.find((image:any) => {
        return image.type === 'users'
      })
      if (logo && logo.file_type === 'svg') logo.file_type = 'svg+xml'
      commit(SignatureMutations.setSignature, logo || null)
    })
  }
}
