import userActions from './user.actions';
import userGetters from './user.getters';
import userMutations from './user.mutations';
import userState from './user.state';

export default {
  state: userState,
  getters: userGetters,
  mutations: userMutations,
  actions: userActions
}
