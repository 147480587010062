import Unit from '@/modules/settings/models/unit.model'
import { UnitStateInterface } from './unit.state'

export enum UnitMutations {
  setUnits = 'UNIT/SET_UNITS'
}

export default {
  [UnitMutations.setUnits]: (state: UnitStateInterface, payload: Unit[]): void => {
    state.units = payload
  }
}
