import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import '@/assets/styles/sass/app.scss';
import i18n from './i18n'
import setupPlugins from '@/setup/setup-plugins'

Vue.use(setupPlugins)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
