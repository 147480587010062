import { ActionContext } from 'vuex'

interface IStateSidebarVertical {
  verticalSidebar: {
    isVerticalSidebar: boolean,
    isVerticalCompact: boolean,
    isMobileCompact: boolean
  },
  verticalCompact: {
    isSidebarCompact: boolean,
    isItemName: boolean
  }
}

const state: IStateSidebarVertical = {
  verticalSidebar: {
    isVerticalSidebar: true,
    isVerticalCompact: false,
    isMobileCompact: true
  },
  verticalCompact: {
    isSidebarCompact: false,
    isItemName: false
  }
};

const getters = {
  getVerticalSidebar: (state: IStateSidebarVertical) => state.verticalSidebar,
  getVerticalCompact: (state: IStateSidebarVertical) => state.verticalCompact
};

const actions = {
  switchSidebar({ commit }: ActionContext<IStateSidebarVertical, any>, data: any) {
    commit('SWITCH_SIDEBAR', data);
  },
  sidebarCompact({ commit }: ActionContext<IStateSidebarVertical, any>, data: any) {
    commit('SIDEBAR_COMPACT', data);
  },
  removeSidebarCompact({ commit }: ActionContext<IStateSidebarVertical, any>, data: any) {
    commit('REMOVE_SIDEBAR_COMPACT', data);
  },
  mobileSidebar({ commit }: ActionContext<IStateSidebarVertical, any>, data: any) {
    commit('MOBILE_SIDEBAR', data);
  }
};

const mutations = {
  SWITCH_SIDEBAR(state: IStateSidebarVertical) {
    state.verticalSidebar.isVerticalSidebar = !state.verticalSidebar
      .isVerticalSidebar;
    state.verticalSidebar.isVerticalCompact = !state.verticalSidebar
      .isVerticalCompact;
  },
  SIDEBAR_COMPACT(state : IStateSidebarVertical) {
    if (state.verticalSidebar.isVerticalSidebar === false) {
      state.verticalCompact.isSidebarCompact = !state.verticalCompact
        .isSidebarCompact;
      state.verticalCompact.isItemName = !state.verticalCompact.isItemName;
    }
  },
  REMOVE_SIDEBAR_COMPACT(state : IStateSidebarVertical) {
    if (state.verticalCompact.isSidebarCompact === true) {
      state.verticalCompact.isSidebarCompact = !state.verticalCompact
        .isSidebarCompact;
      state.verticalCompact.isItemName = !state.verticalCompact.isItemName;
    }
  },
  MOBILE_SIDEBAR(state : IStateSidebarVertical) {
    state.verticalSidebar.isMobileCompact = !state.verticalSidebar
      .isMobileCompact;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
