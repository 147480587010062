import User from '@/modules/user/models/user.model'

export interface UserStateInterface {
  user: User | null
  token: any
  refreshing: boolean
  refresh: any
}

export default (): UserStateInterface => ({
  user: null,
  token: null,
  refreshing: false,
  refresh: null
})
