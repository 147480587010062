import User from '@/modules/user/models/user.model'
import { UserStateInterface } from '@/store/modules/user/user.state'

export enum UserMutations {
  setUser = 'USER/SET_USER',
  setToken = 'USER/SET_TOKEN',
  setRefreshing = 'USER/SET_REFRESHING',
  setRefresh = 'USER/SET_REFRESH',
  removeRefresh = 'USER/REMOVE_REFRESH'
}

export default {
  [UserMutations.setUser]: (state: UserStateInterface, payload: User | null): void => {
    state.user = payload
  },
  [UserMutations.setToken]: (state: UserStateInterface, payload: any): void => {
    state.token = payload
  },
  [UserMutations.setRefreshing]: (state: UserStateInterface, payload: boolean): void => {
    state.refreshing = payload
  },
  [UserMutations.setRefresh]: (state: UserStateInterface, payload: any): void => {
    state.refresh = payload
  },
  [UserMutations.removeRefresh]: (state: UserStateInterface): void => {
    clearInterval(state.refresh)
  }
}
