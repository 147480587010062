


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import http from '@/shared/helpers/http'
import FormField from '@/shared/components/form/FormField.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import Field from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from '@/helpers/__'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'

@Component({
  components: {
    FormField,
    LoadingSpinner
  },
  methods: { __ }
})
export default class Form extends Vue {
  @Prop() form!: FormBase
  errorList: any = null
  loadedEntry: boolean = false

  created(): void {
    this.presetInitialValues()
  }

  async submit(): Promise<void> {
    this.form.setLoading(true)
    this.form.setSuccessTimer(0)
    this.form.setErrorToggle(false)
    if (this.form.beforeSubmit) await this.form.beforeSubmit()
    const { endpoint, model, onSuccess, initialCall } = this.form
    const uuid = this.form.preventUuidSubmit ? null : this.form.uuid
    let data = this.generatePostData()
    data = this.form.changeDataBeforeSubmit ? this.form.changeDataBeforeSubmit(data, this.form) : data
    const call: any = http[this.form.method]

    if ((!endpoint || !call) && onSuccess) {
      this.form.setLoading(false)
      return onSuccess(data)
    }
    const callByEndpoint = initialCall ? endpoint : uuid ? `${endpoint}/${uuid}` : endpoint
    await call(callByEndpoint, data, this.defaultParams())
      .then((response: IResponse<IModelResponse>) => {
        const Model = model
        let entry = null
        try {
          entry = model ? new Model(response.data) : null
        } catch (err) {
          const error: any = err
          this.errorList = error?.response?.data?.exception
          this.form.setErrorToggle(true)
        }
        this.form.setSuccessTimer(10)
        this.form.setErrors({})
        onSuccess && onSuccess(response.data, entry)
        return response
      }).then(() => {
        this.$root.$emit('formSuccess')
      })
      .catch((error: any) => {
        this.form.catchErrors(error)
        if (error?.response?.data?.errors) this.errorList = this.form.errors
        else this.errorList = error?.response?.data?.exception
        this.form.setErrorToggle(true)
      })
      .finally(() => this.form.setLoading(false))
  }

  reset(): void {
    this.presetInitialValues()
  }

  private presetInitialValues() {
    if (this.form.entry) {
      this.parseValues(this.form.entry)
    } else if (this.form.uuid) {
      this.loadEntryAndPresetValues()
    } else {
      this.presetValues()
    }
  }

  private generatePostData() {
    let data = _.cloneDeep(this.form.data)
    const recursive = (key: string, found?: Field | any, parentIndex: number | null = null) => {
      if (!found) found = this.form.fields.find((field: Field) => field.key === key)
      if (!found || !found.visibleIf(data, parentIndex)) _.unset(data, key)
    }

    Object.keys(data).forEach((key: string) => recursive(key))

    data = this.injectValuesToData(data)

    return this.form.files ? this.formDataForFileFormat(data) : data
  }

  private formDataForFileFormat(data: any) {
    const { uuid } = this.form
    const formData = new FormData()

    formData.append('_method', uuid ? 'PUT' : 'POST')

    Object.keys(data).forEach((key: string) => {
      const value = data[key]

      formData.append(key, value)
    })

    return formData
  }

  private presetValues() {
    const data = this.getDefaultFieldValues()
    this.form.setData(data)
  }

  private getDefaultFieldValues() {
    const data: any = {}
    this.form.fields.forEach((field: Field) => {
      const value = Form.setDefaultFieldValue(field, null, this.form)

      _.set(data, field.key, value)
    })
    return data
  }

  public static setDefaultFieldValue(
    field: Field | any,
    entry: any = null,
    form: FormBase | null = null,
    initialValues?: any
  ) {
    const key = field.entryKey ? field.entryKey : field.key
    let value: any = ''
    const initValues = initialValues || form?.initialValues
    if (form) value = _.get(initValues, key, null)
    if (entry) value = _.get(entry, key, null)

    switch (field.type) {
      case FieldTypes.checkbox:
        value = field.uncheckedValue
        break
      default:
        if (value === null) value = ''
        break
    }

    return value
  }

  private loadEntryAndPresetValues() {
    const { endpoint, uuid, model, initialCall } = this.form
    const callByEndpoint = initialCall ? endpoint : `${endpoint}/${uuid}`
    this.form.setLoading(true)

    http
      .get(callByEndpoint, this.defaultParams())
      .then((response: IResponse<IModelResponse>) => {
        const Model = model
        const entry = new Model(response.data.data)
        this.form.setEntry(entry)
        if (this.form.updateInjectValues) {
          this.form.setInjectValues({
            ...this.form.injectValues,
            ...this.form.updateInjectValues(entry)
          })
        }
        this.parseValues(entry)
        this.loadedEntry = true
        this.form.setLoading(false)
      })
      .finally(() => this.form.finallyFunc && this.form.finallyFunc(this.form))
  }

  private defaultParams() {
    return {
      params: {},
      headers: {
        'Content-Type': this.form.files ? 'multipart/form-data' : 'application/json'
      }
    }
  }

  private parseValues(entry: any): void {
    const data: any = this.getDefaultFieldValues()

    this.form.fields.forEach((field: Field) => {
      const key = field.entryKey ? field.entryKey : field.key
      if (!Object.prototype.hasOwnProperty.call(entry, key)) return

      data[field.key] = entry[key]
    })

    this.form.setData(data)
    this.loadedEntry = true
  }

  private injectValuesToData(data: any): void {
    const recursive = (fullKey: string) => {
      const value: any = _.get(this.form.injectValues, fullKey)
      if (value instanceof Object) {
        Object.keys(value).forEach((subKey: string) => recursive(`${fullKey}.${subKey}`))
        return
      }

      if (_.get(data, fullKey, null) === null || _.get(data, fullKey, null) === '') _.set(data, fullKey, value)
    }

    if (this.form.injectValues) {
      Object.keys(this.form.injectValues).forEach(recursive)
    }

    return data
  }

  get fields() {
    return this.form.fields.filter(field => field.visibleIf(this.form.data))
  }
}
