import { SignatureStateInterface } from './signature.state'

export enum SignatureMutations {
  setSignature = 'SIGNATURE/SET_SIGNATURE'
}

export default {
  [SignatureMutations.setSignature]: (state: SignatureStateInterface, payload: any): void => {
    state.signature = payload
  }
}
