import Vue from 'vue'
import Vuex from 'vuex'
import largeSidebar from '@/store/modules/largeSidebar'
import compactSidebar from '@/store/modules/compactSidebar'
import verticalSidebar from '@/store/modules/verticalSidebar'
import vat from '@/store/modules/vat/vat.index'
import unit from '@/store/modules/unit/unit.index'
import user from '@/store/modules/user/user.index'
import logo from '@/store/modules/logo/logo.index'
import signature from '@/store/modules/signature/signature.index'
import paymentMethod from '@/store/modules/payment-method/payment-method.index'
import modalDialog from '@/store/modules/modalDialog';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    verticalSidebar,
    vat,
    unit,
    paymentMethod,
    user,
    logo,
    signature,
    modalDialog
  }
})
