import MiddlewareInterface from '@/router/middleware/middleware.interface'
import { GeneralRoutes } from '@/router/routes/general'
import tokenHelper from '@/helpers/token'

export default function guest({ next, router }: MiddlewareInterface) {
  if (tokenHelper.isTokenSet()) {
    return router.push({ name: GeneralRoutes.dashboard })
  }
  return next()
}
