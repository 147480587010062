


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__';
import general, { GeneralRoutes } from '@/router/routes/general';
import _ from 'lodash';

@Component({ methods: { __ } })
export default class Breadcrumbs extends Vue {
  @Prop() currentPage!: string

  GeneralRoutes = GeneralRoutes
  general = general
  breadcrumbs: BreadcrumbsItem[] = []
  loading: boolean = true

  created(): void {
    this.updateBreadcrumbs()
  }

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(): void {
    this.updateBreadcrumbs()
  }

  updateBreadcrumbs() {
    this.loading = true
    this.breadcrumbs = []
    if (this.$route.meta.path) {
      this.$route.meta.path.forEach((routeName: string) => {
        const navigationItem = _.find(general, (generalLinkItem) =>
          generalLinkItem.name === routeName
        )
        if (navigationItem) {
          this.breadcrumbs.push({
            heading: navigationItem.meta.heading,
            name: navigationItem.name
          })
        }
      })
    }
    this.loading = false
  }
}

interface BreadcrumbsItem {
  heading?: string
  name?: string
}
